import { useRouter } from "next/navigation";
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import pkg from "../../../package.json";

interface Version {
  version: string;
}

export interface Notice {
  application: string;
  title: string;
  kr: string;
  en: string;
  cn: string;
  jp: string;
  startDate: string;
  endDate: string;
  type: string;
  status: string;
  message?: string;
}

interface EventSourceData {
  version: Version;
  s_maintenance: string;
  notice: Notice[];
  maintenance: boolean;
}

interface EventSourceContextProps {
  messageNotice: EventSourceData;
  activeMaintenance: boolean;
}
interface EventSourceErrorMessage {
  message: string;
  code: number;
}

const EventSourceContext = createContext<EventSourceContextProps | undefined>(
  undefined,
);

const initialEventSourceData: EventSourceData = {
  version: { version: "" },
  s_maintenance: "",
  notice: [],
  maintenance: false,
};

const EventSourceProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const version = pkg.version;
  const [messageNotice, setMessageNotice] = useState<EventSourceData>(
    initialEventSourceData,
  );
  const router = useRouter();
  const [activeMaintenance, setActiveMaintenance] = useState(false);
  const prevActiveMaintenanceRef = useRef<boolean>(activeMaintenance);
  const sourceRef = useRef<EventSource | null>(null);
  const url = `${process.env.NEXT_PUBLIC_API_URL}/middlewareapi/maintenance?name=clubhome`;

  const maintenanceOrigin = process.env.NEXT_PUBLIC_MAINTENANCE_URL;

  const startEventSource = useCallback(() => {
    if (sourceRef.current) {
      sourceRef.current.close();
    }

    const source = new EventSource(url);
    sourceRef.current = source;
    const origin = window.location.origin;
    const isMaintenanceOrigin = origin === maintenanceOrigin;

    source.onmessage = (event) => {
      const newMessage: EventSourceData = JSON.parse(event.data);
      setMessageNotice((prev) => {
        return JSON.stringify(prev) !== JSON.stringify(newMessage)
          ? newMessage
          : prev;
      });
    };

    source.onerror = (event) => {
      if (!isMaintenanceOrigin) {
        const { data } = event as MessageEvent;
        if (data) {
          const dataJSON: EventSourceErrorMessage = JSON.parse(data);
          if (dataJSON.code === 40050) {
            setActiveMaintenance(true);
          }
        }
      }
    };

    return () => {
      source.close();
      sourceRef.current = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  useEffect(() => {
    const cleanup = startEventSource();
    return cleanup;
  }, [startEventSource]);

  useEffect(() => {
    const origin = window.location.origin;
    const isMaintenanceOrigin = origin === maintenanceOrigin;
    // console.log(isMaintenanceOrigin);

    if (!isMaintenanceOrigin) {
      const newActiveMaintenance = messageNotice.maintenance;
      // const currentTime = Math.floor(Date.now() / 1000);
      // const maintenanceActive = messageNotice.s_maintenance !== "";

      // let newActiveMaintenance = false;
      // if (maintenanceActive && messageNotice.s_maintenance) {
      //   const [rangeStart, rangeEnd] = messageNotice.s_maintenance
      //     .split(":")
      //     .map(Number);
      //   newActiveMaintenance =
      //     currentTime >= rangeStart && currentTime <= rangeEnd;
      // }

      if (newActiveMaintenance !== prevActiveMaintenanceRef.current) {
        setActiveMaintenance(newActiveMaintenance);
        prevActiveMaintenanceRef.current = newActiveMaintenance;
        if (newActiveMaintenance) {
          localStorage.clear();
          sessionStorage.clear();
          router.push("/login");
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageNotice.maintenance, router]);

  useEffect(() => {
    if (
      version &&
      messageNotice.version.version &&
      version !== messageNotice.version.version
    ) {
      window.location.reload();
    }
  }, [version, messageNotice.version.version]);

  return (
    <EventSourceContext.Provider value={{ messageNotice, activeMaintenance }}>
      {children}
    </EventSourceContext.Provider>
  );
};

const useEventSourceContext = () => {
  const context = useContext(EventSourceContext);
  if (!context) {
    throw new Error(
      "useEventSourceContext must be used within an EventSourceProvider",
    );
  }
  return context;
};

export { EventSourceProvider, useEventSourceContext };
