import React from "react";
import Image from "next/image";
import LoginBackground from "@/assets/login-bg.png";
import { useDomainCheck } from "@/common/context/DomainContext";

const AuthLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const isTargetDomain = useDomainCheck();

  return (
    <div
      className="fixed inset-0 bg-cover bg-center bg-no-repeat sm:bg-top"
      style={{
        backgroundImage: `${isTargetDomain ? "url(/assets/heritage.png)" : "url(/assets/bg_wlogo.jpg)"}`,
      }}
    >
      {/* <Image
        priority
        src={LG}
        alt=""
        fill
        style={{ objectFit: "cover", objectPosition: "center" }}
      /> */}
      <main>{children}</main>
    </div>
  );
};
export default AuthLayout;
